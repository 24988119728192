import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: '',
      email: '',
      memberSince: '',
      role: 1,
      pfp: '',
      is2FAEnabled: false,
      menu: [],
      default_menu: 'dashboard',
      permission: 1
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    updateUser(user: any) {
      this.userName = user.user_name,
      this.email = user.email,
      this.role = user.user_role[0].id,
      this.pfp = user.f_image ? user.f_image.replace('.', import.meta.env.VITE_SERVER_DOMAIN) : ''
    },

    updateUserMenu(menu: [], default_menu: string) {
      this.menu = menu;
      this.default_menu = default_menu;
    },

    updatePermission(permission: number) {
      this.permission = permission;
    }
  },
})
