import axios from "axios";

const HEADERS = {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  'Authorization': ""
};

const HEADERS_NOT_AUTHORIZATION = {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
};

function login(cb, email, password) {
  var params = {
    query: `mutation {login(email:"${email}", password:"${password}"){id, email, user_name, f_image, access_token, kn_office_id, user_role{id}}}`
  };
  axios.post(import.meta.env.VITE_API_DOMAIN, params, {
    headers: HEADERS_NOT_AUTHORIZATION
  }).then(
    //Resolve
    function (res) {
      setTimeout(() => cb(res.data), 100);
    },
    //Reject
    function (res) {
      setTimeout(() => cb({ status: "error", message: res }), 100);
    }
  );
}

function executeAPI(cb, params, is_multipart = false) {
  let token = sessionStorage.getItem("KnToken") || localStorage.getItem("KnToken");
  if (!token) {
    window.location.href = '/auth/login';
  } else {
    HEADERS['Authorization'] = "Bearer " + token;
    if (is_multipart) {
      HEADERS['Content-Type'] = 'multipart/form-data; boundary=something';
    } else {
      HEADERS['Content-Type'] = 'application/json';
    }
    axios.post(import.meta.env.VITE_API_DOMAIN, params, {
      headers: HEADERS,
    }).then(
      function (res) {
        setTimeout(() => cb(res.data), 100);
      },
      function (res) {
        setTimeout(() => cb({ status: "error", message: res }), 100);
      }
    );
  }
}

function getUsers(cb, order_column, order_direction, page_size, page_index, filter) {
  let queryParams = `order_column: "${order_column}", order_direction: ${order_direction}, page_size: ${page_size}, page_index: ${page_index}`;
  for (let key in filter) {
    if (filter[key]) {
      if (typeof filter[key] == 'string') {
        queryParams += `, ${key}: "${filter[key]}"`;
      } else if (Array.isArray(filter[key])) {
        if (filter[key].length > 0) {
          queryParams += `, ${key}: [${filter[key]}]`;
        }
      } else {
        queryParams += `, ${key}: ${filter[key]}`;
      }
    }
  }
  var params = {
    query: `query {users(${queryParams}){total_item, items{id, user_name, email, password, phone_number, birthday, status, f_image, gender, kn_office_id, user_role{id, role_name}, user_tag{id, tag_name}, user_category{id, category_name}}}}`
  };
  executeAPI(cb, params);
}

function getUsersByID(cb, id) {
  var params = {
    query: `query {user(id: ${id}){id, user_name, email, password, phone_number, birthday, status, f_image, gender, default_permission, user_role{id, role_name}, kn_office_id, user_tag{id, tag_name}, user_category{id, category_name}, menu{id, route, display_name, menu_icon, parent_id, sort_order, is_default, permission}}}`
  };
  executeAPI(cb, params);
}

function addItem(cb, user) {
  let queryParams = ``;
  for (let key in user) {
    if (user[key] != null && typeof user[key] != undefined) {
      if (key == 'avatar_upload') {
        queryParams += user[key] ? `, ${key}: $${key}` : '';
      } else if (typeof user[key] == 'string') {
        queryParams += `, ${key}: \\"${user[key]}\\"`;
      } else if (Array.isArray(user[key])) {
        if (user[key].length > 0) {
          queryParams += `, ${key}: [${user[key]}]`;
        }
      } else {
        queryParams += `, ${key}: ${user[key]}`;
      }
    }
  }
  queryParams = queryParams.replace(', ', '');
  var body = new FormData();
  var is_multipart = false;
  if (user.avatar_upload) {
    is_multipart = true;
    body.append(
      'operations',
      `{"query": "mutation addUser($avatar_upload: Upload){addUser(${queryParams}){id}}"}`
    );
    body.append('map', `{"0": ["variables.avatar_upload"]}`);
    body.append('0', user.avatar_upload);
    executeAPI(cb, body, is_multipart);
  } else {
    is_multipart = false;
    queryParams = queryParams.replaceAll('\\', '');
    let params = {
      query: `mutation {addUser(${queryParams}){id}}`,
    };
    executeAPI(cb, params, is_multipart);
  }
}

function updateItem(cb, user) {
  let queryParams = ``;
  for (let key in user) {
    if (user[key] != null && typeof user[key] != undefined) {
      if (key == 'avatar_upload') {
        queryParams += user[key] ? `, ${key}: $${key}` : '';
      } else if (typeof user[key] == 'string') {
        queryParams += `, ${key}: \\"${user[key]}\\"`;
      } else if (Array.isArray(user[key])) {
        if (user[key].length > 0) {
          queryParams += `, ${key}: [${user[key]}]`;
        }
      } else {
        queryParams += `, ${key}: ${user[key]}`;
      }
    }
  }
  queryParams = queryParams.replace(', ', '');
  var body = new FormData();
  var is_multipart = false;
  if (user.avatar_upload) {
    is_multipart = true;
    body.append(
      'operations',
      `{"query": "mutation updateUser($avatar_upload: Upload){updateUser(${queryParams}){id}}"}`
    );
    body.append('map', `{"0": ["variables.avatar_upload"]}`);
    body.append('0', user.avatar_upload);
    executeAPI(cb, body, is_multipart);
  } else {
    is_multipart = false;
    queryParams = queryParams.replaceAll('\\', '');
    let params = {
      query: `mutation {updateUser(${queryParams}){id}}`,
    };
    executeAPI(cb, params, is_multipart);
  }
}

function removeItem(cb, id) {
  var params = {
    query: `mutation {removeUser(id: [${id}])}`
  };
  executeAPI(cb, params);
}

function changePassword(cb, old_password, new_password) {
  var params = {
    query: `mutation {changePassword(old_password: "${old_password}", new_password: "${new_password}"){id}}`
  };
  executeAPI(cb, params);
}

export default {
  login,
  getUsers,
  getUsersByID,
  addItem,
  updateItem,
  removeItem,
  changePassword
};