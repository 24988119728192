<template>
  <div class="app-navbar-actions">
    <VaSwitch
      class="app-navbar-actions__item mr-2"
      v-model="themeValue"
      color="#5123a1"
      off-color="#ffd300"
      @update:model-value="switchDarkMode"
    >
      <template #innerLabel>
        <div class="va-text-center">
          <VaIcon :name="theme == 'dark' ? 'dark_mode' : 'light_mode'" />
        </div>
      </template>
    </VaSwitch>
    <ProfileDropdown class="app-navbar-actions__item app-navbar-actions__item--profile mr-4" />
  </div>
</template>

<script lang="ts" setup>
import ProfileDropdown from './dropdowns/ProfileDropdown.vue';
import { useColors } from 'vuestic-ui'
import { ref, computed, onMounted } from 'vue'

var themeValue  = ref(false);

const { applyPreset, currentPresetName } = useColors()
const theme = computed({
  get() {
    return currentPresetName.value
  },
  set(value) {
    applyPreset(value)
  },
})

function switchDarkMode() {
  theme.value === 'dark' ? theme.value = 'light' : theme.value = 'dark'
}

onMounted(() => {
  if (import.meta.env.VITE_THEME) {
    theme.value = import.meta.env.VITE_THEME;
    themeValue.value = import.meta.env.VITE_THEME === 'dark';
  }
});
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
