import _ from 'lodash';
import { useUserStore } from '../stores/user-store'

export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || 'Please enter a valid email address'
  },
  required: (v: any) => !!v || 'This field is required',
}

export const renderMenu = (role: number, menu: any) => {
  const store = useUserStore();
  let sortedMenu = _.sortBy(menu, 'sort_order')
  let newMenu: any = [];
  let default_menu = _.find(menu, { is_default: 1 }) ? _.find(menu, { is_default: 1 }).route : (role == 1 ? 'transactions' : menu[0].route);
  sortedMenu.forEach((item: any) => {
    if (item.parent_id == 0) {
      let child = _.map(_.sortBy(_.filter(menu, { parent_id: item.id }), 'sort_order'), (childItem: any) => {
        return {
          id: childItem.id,
          name: childItem.route,
          displayName: childItem.display_name,
          permission: childItem.permission,
          meta: {
            icon: childItem.menu_icon
          }
        }
      });
      newMenu.push({
        id: item.id,
        name: item.route,
        displayName: item.display_name,
        permission: item.permission,
        meta: {
          icon: item.menu_icon
        },
        children: child.length > 0 ? child : null
      })
    }
  });
  store.updateUserMenu(newMenu, default_menu);
}